<template>
  <div>
    <div class="vs-row w-full mb-base mt-5">
      <div v-if="activities.length === 0" class="text-center">
        <p class="text-lg text-gray-400">{{$t('No Data Available')}}</p>
      </div>
      <div v-else class="vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full pb-8 px-4" v-for="(item, index) in activities" :key="item.index" :offset="index > 0 ? 2 : 0">
        <el-card class="card" :body-style="{ padding: '0px' }">
          <div class="cardBody">
            <span :class="item.favorite === 'UNFAVORITE' ? 'text-black' : 'text-blue'" @click="favoriteActitty(item)" class="material-icons btn">favorite</span>
            <img v-if="item.banner !== ''" :src="item.banner" class="image">
            <img v-else src="@/assets/images/logo.png" class="sm:w-52 w-44 py-4 m-auto block">
          </div>
          <div class="footer" style="padding: 14px;">
            <span>{{item.title}}</span>
            <div class="mt-1">
              <i class="el-icon-location blue"></i><span class="time mx-1"> {{item.location[0].branch}} | {{item.age_from}}-{{item.age_to}} Years | {{item.interests_name}}</span>
              <div class="mx-1 bottom">
                <span class="blue">{{$t('Price: ')}}</span><span class="orange">{{item.location[0].price}} {{$t('KWD')}}</span>
              </div>
              <a class="blue cursor-pointer">
                <span class="link" @click="openActivityView(item)">{{$t('View Details')}}</span> 
                <span v-if="$route.params.lang === 'en'" class="material-icons float-right mr-1" style="margin-top:-4px;">open_in_new</span>
                <span v-if="$route.params.lang === 'ar'" class="material-icons float-left ml-1" style="margin-top:-4px;">open_in_new</span>
              </a>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div v-if="activities.length !== 0" class="vs-row text-center w-full">
      <vs-button v-if="showMoreFlag" type="border" color="dark" @click="loadMore()">{{$t('Show More')}}</vs-button>
    </div>
  </div>
</template>

<script>
import axios from '../../axios.js'

export default {
  data() {
    return {
      showMoreFlag: true,
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      activities: [],
      page: 1
    }
  },
  methods: {
    loadMore () {
      this.page++ 
      this.loadActivities()
    },
    loadActivities () {
      this.$vs.loading()
      let bodyObj = {}
      if (localStorage.getItem('access_token') && localStorage.getItem('loggedIn')) {
        bodyObj = {activity_type: 'MONTH', page: this.page, limit: 10}
      } else {
        bodyObj = {activity_type: 'DATE', page: this.page, limit: 10}
      }
      axios.post(`${localStorage.getItem('access_token') && localStorage.getItem('loggedIn') ? 
      'v1/user/getActivityList' : 'v1/getActivityListWeb'}`, bodyObj, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.activities = [...this.activities, ...response.data.payload]
          this.showMoreFlag = response.data.payload.length >= 10 ? true : false
          this.$vs.loading.close()
        }
      })
    },
    favoriteActitty (activity) {
      this.$vs.loading()
      axios.post('v1/user/favoriteActivity', {club_activity_id: activity.id}, {headers: this.headers})
      .then(() => {
        if (activity.favorite === 'FAVORITE') {
          activity.favorite = 'UNFAVORITE'
        } else {
          activity.favorite = 'FAVORITE'
        }
        this.$vs.loading.close()
      })
    },
    openActivityView (item) {
      this.$router.push({
        name: 'activity-details',
        params: {
          activityID: item.id
        }
      })
    }
  },
  created () {
    this.loadActivities()
  }
}
</script>

<style scoped>
.el-card {
  height: 377px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
/* el-carousel__item.is-animating {
    transition: transform 10s ease-in-out !important;
} */
.topPicks {
  color: #00DCDC;
  border-bottom: 1px solid #00DCDC;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
[dir = 'rtl'] .link {
  float: left;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.text-blue {
  color:#00DCDC !important;
}
.cardBody .btn {
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .btn:hover {
  background-color: #999;
}
/* .vs-input-primary > .vs-input--input:focus {
  border: 1px solid rgba(5, 218, 220, 1) !important;
}
.vs-input-primary.isFocus .vs-input--label, 
.vs-input-primary .vs-input--input:focus~.icon-inputx, 
.vs-input-primary .vs-input--input:focus~.vs-placeholder-label {
  color: #00DCDC !important;
} */
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  /* border-color: #409EFF; */
  box-shadow: -1px 0 0 0 #409eff;
}
</style>