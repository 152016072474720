<template>
  <div class="vs-row w-full mb-base mt-5">
    <div v-if="interests.length === 0" class="text-center">
      <p class="text-lg text-gray-400">{{$t('No Data Available')}}</p>
    </div>
    <div class="vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full pb-8 px-4" v-for="(item, index) in interests" :key="item.index" :offset="index > 0 ? 2 : 0">
      <el-card :body-style="{ padding: '0px' }">
        <div @click="interestDetail(item)" class="cursor-pointer cardBody">
          <span class="topPicksText">{{item.name}}</span>
          <img v-if="item.photo_name !== ''" :src="item.photo_name" class="image">
          <img v-else src="@/assets/images/logo.png" class="sm:w-52 w-44 py-4 m-auto block">
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import axios from '../../axios.js'
export default {
    data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      interests: []
    }
  },
  methods: {
    loadInterests () {
      axios.get('v1/interestsList', {})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.interests = response.data.payload
        }
      })
    },
    interestDetail(interest) {
      this.$router.push({
        name: 'interest-details',
        params: {
          interestID: interest.interests_id
        }
      })
    }
  },
  created () {
    this.loadInterests()
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
/* el-carousel__item.is-animating {
    transition: transform 10s ease-in-out !important;
} */
.topPicks {
  color: #00DCDC;
  border-bottom: 1px solid #00DCDC;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color:#00DCDC;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .topPicksText {
  color:#000;
  padding: 10px 15px;
  width: 200px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 1.75rem;
  border-top-right-radius: 100px;
  text-align: left;
}
.cardBody .topPicksText:hover {
  color:#fff;
  background-color: #00DCDC;
}
.cardBody .btn:hover {
  background-color: #999;
}
/* .vs-input-primary > .vs-input--input:focus {
  border: 1px solid rgba(5, 218, 220, 1) !important;
}
.vs-input-primary.isFocus .vs-input--label, 
.vs-input-primary .vs-input--input:focus~.icon-inputx, 
.vs-input-primary .vs-input--input:focus~.vs-placeholder-label {
  color: #00DCDC !important;
} */
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  /* border-color: #409EFF; */
  box-shadow: -1px 0 0 0 #409eff;
}
</style>