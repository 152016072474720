<template>
  <div class="vs-row w-full mb-base mt-5">
    <div v-if="clubs.length === 0" class="text-center">
      <p class="text-lg text-gray-400">{{$t('No Data Available')}}</p>
    </div>
    <div v-else class="vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full pb-8 px-4" v-for="(item, index) in clubs" :key="item.index" :offset="index > 0 ? 2 : 0">
      <el-card class="card cursor-pointer" :body-style="{ padding: '0px' }">
        <div class="cardBody">
          <span :class="item.favorite === 'UNFAVORITE' ? 'text-black' : 'text-blue'" @click="favoriteClub(item)" class="material-icons btn">favorite</span>
          <img v-if="item.logo !== ''" :src="item.logo" class="image">
          <img v-else src="@/assets/images/logo.png" class="sm:w-52 w-44 py-4 m-auto block">
        </div>
        <div @click="clubDetail(item)" class="footer text-center" style="padding: 14px;">
          <span>{{item.name ? item.name: item.name_en}}</span>
          <div class="mt-1" v-if="item.location_count">
            <i class="el-icon-location blue"></i><span>{{item.location_count ? item.location_count : ''}}</span>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import axios from '../../axios.js'
export default {
  data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      clubs: []
    }
  },
  methods: {
     favoriteClub (club) {
      this.$vs.loading()
      axios.post('v1/user/favorite', {club_id: club.id}, {headers: this.headers})
      .then(() => {
        if (club.favorite === 'FAVORITE') {
          club.favorite = 'UNFAVORITE'
        } else {
          club.favorite = 'FAVORITE'
        }
        this.$vs.loading.close()
      })
    },
    loadClubs () {
      axios.post(`${localStorage.getItem('access_token') && localStorage.getItem('loggedIn') ? 
      'v1/user/getClubList' : 'v1/getClubListWeb'}`, {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.clubs = response.data.payload
        }
      })
    },
    clubDetail (club) {
      this.$router.push({
        name: 'club-details',
        params: {
          clubID: club.id
        }
      })
    }
  },
  created () {
    this.loadClubs()
  }
}
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
/* el-carousel__item.is-animating {
    transition: transform 10s ease-in-out !important;
} */
.topPicks {
  color: #00DCDC;
  border-bottom: 1px solid #00DCDC;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.text-blue {
  color:#00DCDC;
}
.cardBody .btn {
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .topPicksText {
  color:#000;
  padding: 5px;
  width: 250px;
  background-color: #fff;
  position: absolute;
  bottom: -20px;
  left: 125px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 1.25rem;
  line-height: 1.75rem;
  border: none;
  text-align: center;
}
.cardBody .btn:hover {
  background-color: #999;
}
/* .vs-input-primary > .vs-input--input:focus {
  border: 1px solid rgba(5, 218, 220, 1) !important;
}
.vs-input-primary.isFocus .vs-input--label, 
.vs-input-primary .vs-input--input:focus~.icon-inputx, 
.vs-input-primary .vs-input--input:focus~.vs-placeholder-label {
  color: #00DCDC !important;
} */
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  /* border-color: #409EFF; */
  box-shadow: -1px 0 0 0 #409eff;
}
</style>