<template>
  <div>
    <div class="vs-row">
      <el-carousel :interval="10000" v-if="window.width >= 768" height="500px">
        <el-carousel-item v-for="item in images" :key="item.index">
          <img class="w-full h-full" :src="item.photo_name" alt="">
        </el-carousel-item>
      </el-carousel>
      <el-carousel :interval="10000" v-if="window.width < 768" height="250px">
        <el-carousel-item v-for="item in images" :key="item.index">
          <img class="w-full h-full" :src="item.photo_name" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="ml-8 mr-8 my-5 pt-8">
      <el-tabs type="card">
        <el-tab-pane label="Activities">
          <activities />
        </el-tab-pane>
        <el-tab-pane label="Clubs">
          <clubs />
        </el-tab-pane>
        <el-tab-pane label="Events">
          <events />
        </el-tab-pane>
        <el-tab-pane label="Interests">
          <interests />
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="top-picks vs-row w-full pt-20">
      <div class="vs-row w-1/3 lg:w-1/6 md:w-1/4 mb-7 ml-12 mr-12 topPicks">
        <p class="text-2xl pb-4">{{$t('Popular on join')}}</p>
      </div>
      <div class="vs-row w-full px-12">
        <vueper-slides
          class="no-shadow"
          :visible-slides="4"
          slide-multiple
          :gap="3"
          :slide-ratio="1 / 4"
          :dragging-distance="200"
          :breakpoints="{ 1300: { visibleSlides: 3, slideMultiple: 3 }, 1000: { visibleSlides: 2, slideMultiple: 2 }, 600: { visibleSlides: 1, slideMultiple: 1 } }">
          <vueper-slide v-for="item in mostSelling" :title="item.title" :key="item.index" :image="item.photo_name ? item.photo_name : item.banner" />
        </vueper-slides>
      </div>
    </div>

    <!-- <div class="vs-row w-full mt-32">
      <div class="vs-row text-center xl:w-1/4 lg:w-1/3 md:w-2/5 sm:w-1/2 w-3/4 m-auto mb-16">
        <p class="text-2xl pb-2" style="color: #00DCDC">{{$t('Our Clients')}}</p>
        <p class="text-xl">Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed</p>
      </div>
      <div class="vs-row w-full">
        <el-carousel arrow="never" height="150px" :interval="4000" type="card">
          <el-carousel-item class="vs-row m-auto" v-for="item in 6" :key="item.index">
            <el-card class="m-auto w-3/4" shadow="always" style="height:130px; border-radius: 20px; box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);" :body-style="{ padding: '0px' }">
              <img style="height:130px" class="block m-auto medium" src="@/assets/images/logo.png">
            </el-card>
          </el-carousel-item>
        </el-carousel>
      </div><br><br>
      <div class="vs-row w-full">
        <el-carousel :interval="6000" indicator-position="outside" arrow="never" height="150px">
          <el-carousel-item class="vs-row xl:ml-28 sm:ml-32 ml-40 m-auto" v-for="item in 3" :key="item.index">
            <div class="vs-col m-auto xl:w-1/6 lg:w-1/5 md:w-1/4 sm:w-1/3 w-1/2 pb-8 px-3" v-for="(o, index) in 6" :key="index" :offset="index > 0 ? 2 : 0">
              <el-card class="m-auto" shadow="always" style="height:130px; border-radius: 20px; box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);" :body-style="{ padding: '0px' }">
                <img class="m-auto block" style="height: 130px;" src="@/assets/images/logo.png">
              </el-card>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->

  </div>
</template>

<script>
import axios from '../axios.js'
import Clubs from './components/ClubsTab.vue'
import Activities from './components/ActivitiesTab.vue'
import Interests from './components/InterestsTab.vue'
import Events from './components/EventsTab.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default ({
  data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      images: [],
      mostSelling: [],
      window: {
        width: 0
      },
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
    loadCarasoul () {
      this.$vs.loading()
      axios.post(`${localStorage.getItem('access_token') && localStorage.getItem('loggedIn')? 'v1/user/promoBanner' : 'v1/promoBannerOpen'}`, {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.images = response.data.payload
          this.loadFilters()
          this.$vs.loading.close()
        }
      })
    },
    loadFilters () {
      this.$vs.loading()
      axios.get('v1/mostSelling', {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.mostSelling = response.data.payload
          this.$vs.loading.close()
        }
      })
    }
  },
  components: {
    Clubs,
    Activities,
    Interests,
    Events,
    VueperSlides,
    VueperSlide
  },
  created () {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
    this.loadCarasoul()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
})
</script>

<style scoped>
.btn {
  padding: 7px 25px;
  border-radius: 0px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
@media (max-width: 668px) {
  .transbox {
    bottom: 25% !important;
  }
}
@media (max-width: 534px) {
  .transbox {
    bottom: 20% !important;
  }
}
@media (max-width: 438px) {
  .transbox {
    bottom: 10% !important;
  }
}
/* el-carousel__item.is-animating {
    transition: transform 10s ease-in-out !important;
} */
.topPicks {
  color: #00DCDC;
  border-bottom: 1px solid #00DCDC;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
/* .vs-input-primary > .vs-input--input:focus {
  border: 1px solid rgba(5, 218, 220, 1) !important;
}
.vs-input-primary.isFocus .vs-input--label, 
.vs-input-primary .vs-input--input:focus~.icon-inputx, 
.vs-input-primary .vs-input--input:focus~.vs-placeholder-label {
  color: #00DCDC !important;
} */
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  /* border-color: #409EFF; */
  box-shadow: -1px 0 0 0 #409eff;
}
</style>